<template>
  <div class="main-content">
    <h3 class="marginBottom20">{{ type === 'create' ? '新增' : '编辑' }}</h3>
    <div>
      <el-button @click="goBack()">返回</el-button>
    </div>
    <avue-form
      ref="form"
      v-model="form"
      v-loading="loading"
      :option="formOption"
      @submit="submit"
    >
      <template slot="carTypeId">
        <el-select
          v-model="form.carTypeId"
          placeholder="请选择车型类型"
          @change="getcarTypeListHandle"
        >
          <el-option
            v-for="item in carTypeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </template>

      <template slot="code">
        <el-input
          v-if=" type === 'create'"
          v-model="form.code"
          maxlength="50"
          placeholder="请输入"
        ></el-input>
        <span v-else>{{ form.code }}</span>
      </template>

      <template slot="bindState">
        <el-switch
          v-model="form.bindState"
          :inactive-value="2"
          :active-value="1"
        ></el-switch>
      </template>

      <template slot="startState">
        <el-switch
          v-model="form.startState"
          :inactive-value="2"
          :active-value="1"
        ></el-switch>
      </template>

      <template slot="menuForm">
        <el-button
          icon="el-icon-close"
          @click="handleClose"
        >取消</el-button>
      </template>

    </avue-form>

  </div>
</template>

<script>
import { add, update, view, getcarTypeList } from "@/api/car/manage";

export default {
  name: "create",
  data() {
    return {
      type: this.$route.query.type,
      loading: false,
      form: {
        state: 1,
      },
      formOption: {
        submitText: "确定",
        emptyBtn: false,
        labelWidth: 150,
        column: [
          {
            label: "车型类型",
            prop: "carTypeId",
            type: "select",
            span: 16,
            row: true,
            rules: [
              {
                required: true,
                message: "请选择车型类型",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value === "") {
                    callback(new Error("请选择车型类型"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
          {
            label: "车辆编号",
            prop: "code",
            maxlength: 50,
            showWordLimit: true,
            span: 16,
            row: true,
            placeholder: "请输入车辆编号",
            rules: [
              {
                required: true,
                message: "请输入车辆编号",
                trigger: "blur",
              },
            ],
          },
          {
            label: "TBOX",
            prop: "tbox",
            maxlength: 50,
            showWordLimit: true,
            span: 16,
            row: true,
            placeholder: "请输入TBOX",
            rules: [
              {
                required: true,
                message: "请输入TBOX",
                trigger: "blur",
              },
            ],
          },
          {
            label: "绑定开关",
            prop: "bindState",
            span: 24,
            maxlength: 4,
            showWordLimit: true,
            // rules: [
            //   {
            //     required: true,
            //     message: "请选择上架状态",
            //     trigger: "blur",
            //   },
            // ],
          },
          {
            label: "启动开关",
            prop: "startState",
            span: 24,
            maxlength: 4,
            showWordLimit: true,
            // rules: [
            //   {
            //     required: true,
            //     message: "请选择上架状态",
            //     trigger: "blur",
            //   },
            // ],
          },
        ],
      },
      disabled: false,
      carTypeList: [],
    };
  },
  mounted() {
    this.getcarTypeListHandle();
    if (this.$route.query.id) {
      this.getView();
      this.disabled = true;
    }
  },
  methods: {
    getcarTypeListHandle() {
      getcarTypeList().then((res) => {
        if (res.code === 200) {
          this.carTypeList = res.data;
        }
      });
    },
    salaryChange(e, t, max) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max;
            e.target.value = max;
          } else {
            this.form[t] = value * 1;
            e.target.value = value * 1;
          }
        }
      } else {
        this.form[t] = "";
      }
    },
    getView() {
      this.loading = true;
      view({
        id: this.$route.query.id,
      }).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          this.form = res.data;
        } else {
          this.$router.go(-1);
          this.$message.error(res.msg);
        }
      });
    },
    submit(form, done) {
      if (form.id) {
        update(form)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("修改成功");
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
              done();
            }
          })
          .catch(() => {
            done();
          });
      } else {
        add(form)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("新增成功");
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
              done();
            }
          })
          .catch(() => {
            done();
          });
      }
    },
    handleClose() {
      this.$router.go(-1);
    },
    salaryDianChange(e, t, max) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max.toFixed(2);
            e.target.value = max.toFixed(2);
          } else {
            if (
              (t === "useConditionSubtractPrice" || t === "takePrice") &&
              value < 0.01
            ) {
              this.form[t] = 0.01;
              e.target.value = 0.01;
              return;
            }
            this.form[t] = parseFloat(value).toFixed(2);
            e.target.value = parseFloat(value).toFixed(2);
          }
        }
      } else {
        this.form[t] = "";
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
</style>